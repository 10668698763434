<template>
  <section>
    <div class="grid grid-cols-2 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">Registro Médico</span>
        <InputText class="rounded w-full border uppercase" :disabled="idMed" type="number" v-model="model.RegMedico" />
        <MessageError :text="errors.RegMedico"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Nombre Médico</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.NomMedico" />
        <MessageError :text="errors.NomMedico"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import MedicoService from '../../../../../services/medico.service'

// instancias
const _MedicoService = ref(new MedicoService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idMed: String
})
// referencias

const validationSchema = yup.object({
  RegMedico: yup.string().required('El registro es requerido').label(''),
  NomMedico: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('RegMedico', null, { initialValue: '' })
  useField('NomMedico', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idMed) {
      _MedicoService.value.editarMed(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Médico Actualizado',
          text: 'El Médico se ha actualizado correctamente'
        }).then(() => {
          emit('crearMed')
        })
      })
    } else {
      _MedicoService.value.crearMed(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Médico creado',
          text: 'El Médico se ha creado correctamente'
        }).then(() => {
          emit('crearMed')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    RegMedico: '',
    NomMedico: ''
  }
}

onMounted(() => {
  if (props.idMed) {
    _MedicoService.value.getMedico(props.idMed).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
