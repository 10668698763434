import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class MedicosService {
  listarMedicos (name, params) {
    return http.get(`${baseUrl}/medicos/search/${name}`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/medicos/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  getMedico (id) {
    return http.get(`${baseUrl}/medicos/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearMed (data) {
    return http.post(`${baseUrl}/medicos`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarMed (data) {
    return http.put(`${baseUrl}/medicos/${data.RegMedico}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarMed (id) {
    return http.delete(`${baseUrl}/medicos/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
